import Center from '@/views/home/center.vue'

// eslint-disable-next-line no-unused-vars
const en = {
  top: {
    one: 'About us',
    two: 'Our advantages',
    three: 'Business scope',
    four: 'Hosting center resources',
    six: 'Supercomputer server',
    seven: 'Business process',
    eight: 'Core team',
    nine: 'Corporate Vision',
    ten: 'Contact us',
    twen: 'Home', // 首页
    twe: 'News trends',
    more: 'More',
    // 合作伙伴
    partner: 'cooperative partner'

  },
  top_youshi: {
    five: 'Hosting center advantages'
  },
  /* 业务介绍 */
  Bus_introduction: {
    one: 'Trusteeship center',
    two: 'Supercomputer server',
    three: 'Power earnings broadcast',
    three_1: 'Data center co-construction',
    three_2: 'Blockchain Research Institute',
    four: 'Power broadcasting',
    five: 'Theoretical income calculator',
    six: '*Warm reminder',
    seven: 'The return calculator uses the current difficulty to estimate the theoretical return, which may be biased from your actual return, for reference only.',
    eight: 'Effective computing power',
    nine: 'earnings',
    ten: 'Currency rate',
    ele: 'Difficulty',
    elew: 'Rate',
    thirteen: 'BTC coin price',
    fourteen: 'Difficulty',
    fifteen: ''
  },
  line: {
    one: 'The whole network computing power',
    two: 'Price trend',
    three: 'Weeks',
    four: 'Month',
    five: 'Day'
  },
  /* 表格 */
  table_title: {
    one: 'Mainstream miner',
    tow: 'Computing power',
    three: 'Power consumption of miner',
    four: 'Daily output value',
    five: 'Daily electricity charge',
    six: 'degree',
    seven: 'Price per unit',
    eight: 'Payback days'
  },
  /* 超算的产品的tab */
  tabs: {
    one: 'Product 1',
    two: 'Product 2',
    three: 'Product 3',
    four: 'Product 4'
  },
  /* 联系我们 */
  form: {
    one: 'Name',
    two: 'Company name',
    three: 'Mailbox',
    four: 'Phone',
    five: 'Customer message',
    six: 'Please enter full information',
    seven: 'Add successfully'
  },
  /* 新闻 */
  news: {
    one: 'Company news',
    two: 'Industry news',
    three: 'Popular science knowledge',
    four: 'News details',
    five: 'Flash',
    six: 'Source: CCTV News'
  },
  top_1: {
    one: 'Business authorization',
    two: 'Supercomputer server hosting center scale and status',
    three: 'Supercomputer server hosting center display'
  },
  lang: {
    one: '繁體中文',
    two: '简体中文',
    three: 'English',
    four: '日本語'
  },
  banner: {
    one: 'WKT',
    two: 'Wukong Technology Limited',
    three: 'Wukong Technology Limited'
  },
  section1: {
    one: 'Wukong Technology Limited (WKT) was established in August 2023 and is located in Hong Kong, China.',
    two: 'The WKT team is composed of a group of senior industry professionals with more than ten years of experience in the financial, Internet, and blockchain industries, most of whom graduated from the world’s top universities. The project assets managed by the team have accumulated more than 10 billion U.S. dollars.',
    three: 'WKT is committed to serving the globaln market, helping globaln customers and investors to participate in and enjoy high-quality blockchain projects and resources around the world.'
  },
  section2: {
    one: 'Service platform',
    two: 'WKT has a number of well-known US supercomputer server hosting centers in global business authorization',
    three: 'Rich resources',
    four: 'Multiple large and stable partners provide professional services',
    five: 'Trade efficiency',
    six: 'Directly contact customers and partners to implement efficient trade',
    seven: 'Field inspection',
    eight: 'Provide appointment on-site visits and supercomputer server confirmation',
    nine: 'On-site operation and maintenance',
    ten: 'The joint partner provides professional supercomputer server hosting center conditions such as insurance protection, stable power supply, real-time monitoring, and professional operation and maintenance team'
  },
  section3: {
    one: 'BTC supercomputer server hosting center co-construction',
    two: 'BTC supercomputer server sales, repurchase and resale',
    three: 'BTC supercomputer server hosting business',
    three1: '(North America)',
    four: 'BTC supercomputer server hosting business',
    four1: '(Angola)',
    five: 'BTC supercomputer server hosting business',
    five1: '(South Africa)',
    six: 'BTC supercomputer server hosting business',
    six1: '(Ethiopia)'
  },
  section4_1: {
    one: 'WKT has the globaln business authorization of many well-known supercomputer server hosting centers in the United States',
    two: '210MW in Oklahoma',
    three: '41MW in Indiana',
    four: '150MW in Texas',
    five: '* The above data includes planned expansion',
    six: 'The number of hosted supercomputer servers exceeds 15,000, and the number of supercomputer servers sold exceeds 10,000.'
  },
  section4_2: {
    one: 'Data center scale',
    two: 'The BTC supercomputing server data center in Marion, Indiana, with a total size of 20 MW, is now energized.',
    three: 'The supercomputing server data center can accommodate a total of 6,800 supercomputing servers running concurrently.',
    four: 'Operation team',
    five: 'The operation team comes from the top companies in the industry with ten years of maintenance experience. Professional technicians conduct computing power monitoring, temperature monitoring, and supercomputer server online rate of up to 98% 24 hours a day.The cooperative partner Bit Tribe has a professional supercomputer server maintenance team and spare parts for supercomputer servers. When a supercomputer server fails, it is quickly repaired and put back online.',
    // five1: '',
    six: 'Security system',
    seven: 'There are sixteen 4k cameras, eight night vision cameras, and twelve infrared detection alarms in the supercomputer server data center, directly connected to the security systems of the police station and fire department.',
    eight: 'Power supply technology',
    nine: 'The power source is unrestricted clean energy, 12.47kv three-phase y-level delta nuclear power. The advantages of clean energy for BTC mining are very obvious. Nuclear power does not have carbon dioxide pollution and is not subject to government policy constraints.',
    ten: 'The power lines of the substation enter the supercomputer server data center, access a one-in-four-out medium-voltage cabinet, and the medium-voltage cabinet uses the SEL751a series circuit protection device. Each output line connects to a transformer, which changes 12.47kv to 110kv. The transformer output connects to the low-voltage cabinet, which carries the Siemens LSIG quadruple protection circuit breaker. Finally, connect to each mining box. All cables pass through the bridge frame to ensure the stability and safety of the circuit.',
    eleven: 'The BTC data center uses at&t fiber, spectrum fiber, and at&t unlimited routers to ensure the stability of the BTC data center network.'
  },

  section4_3_1: {
    title: 'Georgia Project',
    one: 'Georgia Supercomputer Server Hosting Center',
    two: '210MW has been energized in May 2022',
    three: 'Location: Macon, Georgia',
    four: 'Load: 210MW / 9,000 units',
    five: 'Electricity: National Grid',
    six: 'Capital expenditure: 11.6 million US dollars',
    seven: 'Electricity agreement: Georgia Power Company, RTP pricing, 5 years',
    eight: 'Land: Lease'
  },
  section4_3_2: {
    title: 'Indiana-Marion',
    one: 'Indiana Supercomputer Server Hosting Center',
    two: '42MW has been energized in June 2022',
    three: 'Location: Marion, Indiana',
    four: 'Load: 42MW',
    five: 'Electricity: Nuclear power',
    seven: 'Electricity agreement: Indiana Michigan Power Company, 5 years',
    eight: 'Land: Self-owned'
  },
  section4_3_3: {
    title: 'Texas Project',
    one: 'Texas Supercomputer Server Hosting Center',
    two: '150MW-10MW has been energized in March 2022',
    three: 'Location: Texas',
    four: 'Load: 150MW',
    five: 'Electricity: National Grid',
    six: 'Capital expenditure: 2.55 million US dollars',
    seven: 'Land: Self-owned'
  },
  section4_3_4: {
    title: 'Indiana-Mansi',
    one: 'Indiana Supercomputer Server Hosting Center',
    two: '11MW-December 1, 2023 has been energized',
    three: 'Location: Mansi, Indiana',
    four: 'Load: 11MW / 3300 units',
    five: 'Electricity: Nuclear power',
    six: 'Electricity agreement: Indiana Michigan Power Company, 5 years',
    seven: 'Land: Self-owned'
  },
  section5: {
    one: 'Stable power and electricity prices： 80% of the project’s electricity comes from nuclear energy, and 20% of the electricity is comprehensive (gas and thermal power). Against the background of inflation and rising energy prices, electricity is stable and does not rise.',
    two: 'Significant cluster effect： The local political and business relations are stable, and there is already a stable 20MW mine in the same area, and there are plans to build three mines. In a short period of time, a mine cluster effect will be formed.',
    three: '7X24 real-time control： The entire field has 7x24 hours of security monitoring, 7x24 hours of box monitoring, and 7x24 hours of on-site operation and maintenance. The computing power compliance rate reaches more than 98%, and you can view the monitoring video in real time.',
    four: 'The local environment is safe and advanced： the local policies are friendly, the assets are protected, and the environment is stable, safe and efficient. It is the first choice for high-end and latest machines to land and host.'
  },
  section7: {
    one: 'The entire process of hosting is safe and transparent, and each step has clear records and perfect legal documents to ensure that it does not participate in the customer’s currency distribution process. Each supercomputer server is under the customer’s name, and the customer’s wallet is directly bound to the mining pool for currency distribution.'
  },
  section8: {
    one: 'Vincent',
    two: 'Co-Founder & CEO',
    three: 'Has more than ten years of project investment experience in the financial field, and has participated in and managed investment and financing projects with a scale of more than 2 billion.',
    four: 'Kai Yat',
    five: 'Co-Founder & CTO',
    six: 'More than 10 years of programming experience. Since 2018, he has served as a cryptographer. Participated in the development of sharding blockchain, encrypted wallet and multiple DeFi projects. The DeFi project has accumulated a total locked value (TVL) of more than 100 million U.S. dollars.',
    seven: 'Pom Jack',
    eight: 'Co-Founder & COO',
    nine: 'Graduated from the top 100 universities in the world in 2009 with a major in computer. He has been engaged in the financial industry for 14 years and has managed financial assets and investment projects with a total of more than 5 billion US dollars.'
  },

  section9: {
    one: 'WKT believes that in the future, with the development of blockchain technology, peoples information about cryptocurrencies continues to grow, and BTCs network-wide consensus and beliefs will be unshakeable. Supercomputing servers and supercomputing server data centers, as the BTC net network is a necessary component of the BTC network, and is an investment opportunity not to be missed at this time.',
    two: 'WKT is strategically positioned to establish a blockchain + supercomputing server hosting center one-stop technology platform, dedicated to building a blockchain technology service ecosystem, with in-depth layout of supercomputing server hosting center construction, supercomputing server hosting and digital currency investment in three major segments, and strives to make contributions to customers and investors.'
  },
  section10: {
    one: 'Address: 33rd Floor, Tower 5, Harbour City, 17 Canton Road, Tsim Sha Tsui, Kowloon, Hong Kong',
    two: 'Tel: 852-51250672',
    three: 'Email:webmaster@wukongbc.com'
  },
  goods: {
    one: 'Function',
    two: 'Air-cooled miner',
    three: 'Water-cooled miner',
    four: 'Parameter'
  },
  section11: {
    one: 'Indiana Mine'
  },
  other: {
    one: 'Hosting center video',
    one1: 'Real-time monitoring video',
    two: 'Drone Video 1',
    three: 'Drone Video 2',
    four: 'Entry Video',
    five: '45 days completion video',
    six: 'More',
    seven: 'More news'
  },
  jiankong: {
    one: 'Hosting center external monitoring sample',
    two: 'Hosting center internal monitoring sample',
    three: 'Click for external real-time monitoring',
    four: 'Click for internal real-time monitoring',
    five: 'To log in to your account, please contact us at the following e-mail address',
    six: 'To download the app and to log in to your account, please contact us at the following e-mail address'
  },
  title: {
    one: 'Basic Concept',
    two: 'News Flash',
    three: 'Viewpoint'
  },
  center: {
    title: 'Scarcity',
    title_1: 'Speciality',
    title_2: 'Stability',
    title_3: 'North American joint construction case',
    title_4: '2024 Key areas to be investigated',
    title_5: 'Ethiopia allows bitcoin mining operations',
    title_6: 'Ethiopia needs bitcoin minings',
    content_5: 'The Ethiopian Government has embraced new technologies',
    content_6: 'Due to Ponzi schemes, black market money laundering, and illegal transactions, the central bank has restricted cryptocurrency business',
    content_7: 'The government, driven by some miners and its own economic development considerations, allowed Bitcoin mining operations to be carried out and discussed through parliament',
    content_8: 'Operators currently engaged in cryptocurrency business are required to make a declaration to the INSA platform and obtain approval',
    content_9: `Mining mandates payment of electricity in dollars, which can generate a lot of foreign cash for the Ethiopian government; Due to the conflict with the PLP, the United States cancelled the Ethiopia-Africa High Opportunity Enhancement Act, resulting in the direct loss of more than half of Ethiopia's export trade scale, and the urgent need for US dollars to aid economic construction; Negotiations with the International Monetary Fund and the World Bank for aid loans are stalled because of the wide gap between official and black market exchange rates.`,
    content_10: `Ethiopia's electricity resources are one of the few high-quality hydropower resources, but due to the serious loss of transmission lines, the cost of exporting electricity to other countries is high. The country's current industrial level is low, there is no large-scale energy consumption of large-scale enterprises, excess power nowhere to consume.`,
    one: 'What is data center co-construction?',
    two: 'Data center co-construction is a business in which investors jointly invest in the construction of data centers with partners who have experience in development, construction, operation, and management. After the data center is completed and put into production, it is operated and managed by the partner, and investors enjoy dividends on time.',
    three: 'Why choose data center co-construction?',
    four: 'Scarcity',
    five: 'Due to different views on the development of cryptocurrencies, there are significant differences in the policies of various countries on the mining of digital currencies. Legal data centers are scarce resources in the future.',
    six: 'Professionalism',
    seven: 'WKT has a professional data center operation team, which can select safe, stable, and low-cost data centers for investors and provide professional data center operation and maintenance, so that investors can be more worry-free and labor-saving.',
    eight: 'Stability',
    nine: 'Stable customer base: Taking Bitcoin as an example, for every 10% increase in the price of Bitcoin, an additional 20MW Bitcoin data center can support 40-70. Considering the demand of other cryptocurrencies for data centers, this number still has room for increase (currently Bitcoin’s total market value is close to 50% of the total market value of cryptocurrencies).',
    nine_1: 'Stable cash flow: Once a customer chooses a data center, considering factors such as the time to relocate equipment, failure loss, logistics costs, etc., the possibility of changing data centers is extremely low. And unless the price of the currency is low to a certain extent, leading to mining revenue lower than the cost of electricity, in general, customers will continue to mine.',
    nine_2: 'Stable rate of return: The profitability of data centers is only related to fluctuations in electricity prices, and electricity prices are generally stable.',
    nine_3: 'Business status',
    district_ti: 'District',
    district: 'North America',
    district_1: 'Russia',
    district_2: 'Ethiopia',
    district_3: 'A surname',
    content: 'More than 60 megawatts in operation',
    content_1: 'Capacity under construction is approximately 230 megawatts (MW).',
    content_2: 'A layout of about 100-150MW is planned to start in 2024.',
    content_3: 'A layout of about 80-120MW is planned to start in 2024.',
    content_4: 'The operating load is about 10MW, and 20-150MW is expected to be added.',
    img: '10',
  },
  table_ins: {
    title: 'Account business plan-Table of elements of account business',
    title_1: 'Capital rate arbitrage scheme-Capital rate arbitrage factor table',
    label: "Business model",
    label_1: 'Lockup period',
    label_2: 'Income distribution',
    label_3: 'Stop-loss condition',
    label_4: 'Risk',
    label_5: 'Remark',
    label_6: 'Risk and return',
    label_7: 'Earnings',
    content: `By signing investment agreement with customers, we can operate the spot and contract trading of customers' virtual currency exchange accounts on behalf of customers, and realize profits for customers.`,
    content_1: "1 year",
    content_2: "The initial net value of the account is 1, and the revenue generated for the customer is calculated according to the change of the net value of the account. The single-customer high-water mark method is implemented, and the revenue generated for the customer is divided into steps after the end of each natural month.",
    content_3: "To be added",
    content_4: "The initial net value of the account is 1, and the revenue generated for the customer is calculated according to the change of the net value of the account. The single-customer high-water mark method is implemented, and the revenue generated for the customer is divided into steps after the end of each natural month.",
    content_5: "Customers are required to provide their own exchange account trading rights, do not participate in any customer's currency and fiat currency exchange in the business process, do not carry out virtual currency assets collection operations.",
    content_6: "Money rate arbitrage is a delta neutral strategy that allows traders to take opposite positions in the spot market against the same trade pair as in the contract market, thus achieving the effect of hedging. Losses due to price changes in the contract market can be offset by gains in the spot market (and vice versa), allowing traders to earn money without closing positions.",
    content_7: "Pending",
    content_8: "1.Interest rates are unstable. Super high capital fees will prompt people to close positions, often a flash in the pan.",
    content_9: "2. Warehouse explosion risk. Low hedging margin will increase the risk, and high margin will reduce the utilization rate of funds and thus reduce the return rate.",
    content_10: "High rate of return. In recent months, capital fees have exceeded 50% annualized, and a few non-mainstream currencies in the ranking have reached hundreds or even thousands of years.",
  }
}
// eslint-disable-next-line no-unused-vars
const tw = {
  top: {
    one: '關於我們',
    two: '我們的優勢',
    three: '業務介绍',
    four: '託管中心資源',
    six: '超算服務器',
    seven: '業務流程',
    eight: '核心團隊',
    nine: '企業願景',
    ten: '聯繫我們',
    twen: '首頁',
    twe: '新聞動態',
    more: '更多',
    // 合作伙伴
    partner: '合作伙伴'
  },
  top_youshi: {
    five: '託管中心優勢'
  },
  /* 业务介绍 */
  Bus_introduction: {
    one: '託管中心',
    two: '超算服務器',
    three: '算力收益播報',
    three_1: '數據中心共建',
    three_2: '區塊鏈研究院',
    four: '算力播報',
    five: '理論收益計算器',
    six: '*溫馨提示',
    seven: '收益計算器採用當前難度估算理論收益,可能與您的實際收益存在偏差,僅供參考。',
    eight: '有效算力',
    nine: '收益',
    ten: '幣價',
    ele: '難度',
    elew: '費率',
    thirteen: 'BTC幣價',
    fourteen: '難度',
    fifteen: '費率'
  },
  /* 折线图 */
  line: {
    one: '全網算力',
    two: '價格走勢',
    three: '周',
    four: '月',
    five: '天'
  },
  /* 表格 */
  table_title: {
    one: '主流礦機',
    tow: '算力',
    three: '礦機功耗',
    four: '日產值',
    five: '日費電',
    six: '度',
    seven: '單台價格',
    eight: '回本天數'
  },
  /* 超算的产品的tab */
  tabs: {
    one: '產品一',
    two: '產品二',
    three: '產品三',
    four: '產品四'
  },
  /* 联系我们 */
  form: {
    one: '姓名',
    two: '公司名稱',
    three: '郵箱',
    four: '聯繫電話',
    five: '客戶留言',
    six: '請輸入完整信息!',
    seven: '添加成功'
  },
  /* 新闻 */
  news: {
    one: '公司新聞',
    two: '行業新聞',
    three: '科普知識',
    four: '新聞詳情',
    five: '快訊',
    six: '來源: 央視新聞'
  },
  top_1: {
    one: '業務授權',
    two: '超算服務器託管中心規模與現狀',
    three: '超算服務器託管中心展示'
  },
  lang: {
    one: '繁體中文',
    two: '简体中文',
    three: 'English',
    four: '日本語'
  },

  banner: {
    one: 'WKT',
    // 悟空科技
    two: '悟空科技',
    three: 'Wukong Technology Limited'
  },

  section1: {
    one: '悟空科技（Wukong Technology Limited，簡稱：WKT）成立於2023年8月，位於中國香港。',
    two: 'WKT團隊由一群金融、互聯網、區塊鏈行業從業十年以上的資深行業人士組成，大部分畢業於世界頂尖大學。團隊曾參與管理的項目資產累計超過100億美元。',
    three: 'WKT致力於服務亞洲市場，幫助亞洲的客戶和投資人參與、享受全球的優質區塊鏈專案和資源。'
  },
  section2: {
    one: '服務平臺',
    two: 'WKT擁有多家美國知名超算服務器託管中心的亞洲業務授權',
    three: '資源豐富',
    four: '多家大型穩定合作商，提供專業服務',
    five: '貿易效率',
    six: '直接聯系客戶與合作商，實行高效貿易',
    seven: '實地考察',
    eight: '提供預約現場參觀和超算服務器確認',
    nine: '現場運維',
    ten: '聯合合作方提供保險保障、穩定電力、即時監控、專業運維團隊等專業超算服務器託管中心條件'
  },
  section3: {
    one: 'BTC超算服務器託管中心共同建設',
    two: 'BTC超算服務器銷售、回收和轉售',
    three: 'BTC超算服務器託管業務',
    three1: '(北美地區)',
    four: 'BTC超算服務器託管業務',
    four1: '(安哥拉)',
    five: 'BTC超算服務器託管業務',
    five1: '(南非)',
    six: 'BTC超算服務器託管業務',
    six1: '(衣索比亞)'
  },
  section4_1: {
    one: 'WKT擁有多家美國知名超算服務器託管中心的亞洲業務授權',
    two: '俄克拉荷馬州 210MW',
    three: '印第安那州 41MW',
    four: '德州 150MW',
    five: '*上述數據含計劃擴建部分',
    six: '託管超算服務器數量超過一萬五千台，銷售超算服務器數量超過一萬台。'
  },
  section4_2: {
    one: '數據中心規模',
    two: '印第安那州馬里昂市BTC超算伺服器數據中心總規模為20MW, 現已通电。',
    three: '印第安那州馬里昂市BTC超算伺服器數據中心總規模為20MW, 現已通电。',
    four: '運營團隊',
    five: '運營團隊來自具有十年維護經驗的業內頂級公司，由專業技術人員 24 小時進行算力監測，溫度監測，超算伺服器線上率高達98%。合作方比特部落擁有專業的超算伺服器維修團隊，和超算伺服器備用部件，在超算伺服器出現問題時快速維修重新上線。',
    five1: '',
    six: '安保系統',
    seven: '超算伺服器數據中心內有十六個4k攝像頭，八個夜視攝像頭，十二個紅外線探測警報，直接連接警察局和消防局的安保系統。',
    eight: '供電技術',
    nine: '電力來源為不受限制的清潔能源，12.47kv三相y級對級德爾塔核電。清潔能源對BTC挖礦的優勢非常明顯，核電沒有二氧化碳的污染，不受政府政策制約。',
    ten: '變電站的電線進入超算伺服器數據中心，接入一進四出中壓櫃, 中壓櫃採用SEL751a系列斷路保護裝置。每個出線接一個變壓器，變壓器把12.47kv變到110kv。變壓器出來接低壓櫃，低壓櫃搭載西門子LSIG四重保護斷路保護器。最後連接每一個礦箱。所有的電纜從橋架通過，保障電路的穩定性和安全性。',
    eleven: 'BTC數據中心採用at&t光纖，spectrum光纖以及at&t無限路由器三重網路保障BTC數據中心網路穩定。'
  },
  section4_3_1: {
    title: '俄克拉荷馬州專案',
    one: '俄克拉荷馬州算服務器託管中心',
    two: '210MW-2022年5月已通電',
    three: '位置：俄克拉荷馬州',
    four: '負荷：210MW / 9,000個機位',
    five: '電力：國家電網',
    six: '資本支出：1,160萬美元',
    seven: '電力協議：俄克拉荷馬州電力公司，RTP定價，5年',
    eight: '土地：租賃'
  },

  section4_3_2: {
    title: '印第安納-馬里昂',
    one: '印地安納超算服務器託管中心',
    two: '20MW-2022年6月已通電',
    three: '位置：印第安那州馬里昂地區',
    four: '負荷：20MW',
    five: '電力：核電',
    seven: '電力協定：印第安那州密歇根電力公司，5年',
    eight: '土地：自有'
  },
  section4_3_3: {
    title: '德州專案',
    one: '德州超算服務器託管中心',
    two: '50MW-2022年3月已通電10MW',
    three: '位置：德州',
    four: '負荷：150MW',
    five: '電力：電網',
    six: '資本支出：255萬美金',
    seven: '土地：自有'
  },
  section4_3_4: {
    title: '印第安那-曼西',
    one: '印第安那超算服務器託管中心',
    two: '11MW-2023年12月1日已通電',
    three: '位置：印第安那州曼西地區',
    four: '負荷：11MW / 3300個機位',
    five: '電力：核電',
    six: '電力協議：印第安那州密歇根電力公司，5年',
    seven: '土地：自有'
  },
  section5: {
    one: '電力及電價穩定：項目80%電力來自核能，20%電力為綜合（瓦斯、火電），在通貨膨脹和能源上漲的背景下，電力穩定不上漲。',
    two: '集羣效應顯著：當地政商關係穩健，已有穩定運營的20MW礦場就在同一地區，並有規劃在建礦場三個，短時間內形成礦場集羣效應。',
    three: '7X24實時管控：全場安防監控7x24小時，箱內監控7x24小時，現場有7x24小時的運維維護，算力達標率達到98%以上，可實時查看監控視頻。',
    four: '當地環境安全先進：當地政策友好，資產得到保護，環境穩定、安全、高效，是高端最新機器落地託管的第一選擇。'
  },
  section7: {
    one: '託管全流程安全透明，每一步都有清晰的記錄和完善的法律檔做保障，不參與客戶分幣流程，每一臺超算服務器都在客戶名下，客戶的錢包直接綁定礦池分幣。'
  },
  section8: {
    one: 'Vincent',
    two: 'Co-Founder & CEO',
    three: '擁有超過十年的金融領域項目投資經驗，曾參與和管理的投融資項目規模超過20億。',
    four: 'Kai Yat',
    five: 'Co-Founder & CTO',
    six: '超過10年的程式設計經驗。自2018年起擔任加密開發人員。 參與了分片區塊鏈、加密錢包和多個DeFi項目的覈心開發。DeFi項目累計鎖定總價值（TVL）超過1億美元。',
    seven: 'Pom Jack',
    eight: 'Co-Founder & COO',
    nine: '2009年畢業於全球前100大學電腦專業，從事金融行業長達14年，曾管理的金融資產和投資項目累計超過50億美金。'
  },
  section9: {
    one: 'WKT認為，未來隨著區塊鏈技術的發展，人們對加密貨幣的信息不斷增強，BTC的全網共識和信仰將不可撼動。超算伺服器和超算伺服器數據中心作為BTC網路的必要組成部分，是當下不容錯過的投資機遇。',
    two: 'WKT戰畧定位於建立區塊鏈+超算服務器託管中心一站式科技平臺，致力於打造區塊鏈技術服務生態，深度佈局超算服務器託管中心建設、超算服務器託管和數位貨幣投資三大板塊，努力為客戶和投資人做出貢獻。'
  },
  section10: {
    one: '地址：香港尖沙咀海港城港威大廈5座33樓',
    two: '號碼：852-51250672',
    three: '郵箱：webmaster@wukongbc.com'
  },
  goods: {
    one: '功能',
    two: '風冷礦機',
    three: '水冷礦機',
    four: '參數'
  },
  section11: {
    one: '印第安納礦場'
  },
  other: {
    one: '托管中心視頻',
    one1: '實時監控視頻',
    two: '無人機視頻1',
    three: '無人機視頻2',
    four: '進場視頻',
    five: '45天完工視頻',
    six: '更多',
    seven: '更多新聞'
  },
  jiankong: {
    one: '托管中心外部監控樣例',
    two: '托管中心內部監控樣例',
    three: '外部即時監控請點擊',
    four: '內部即時監控請點擊',
    five: '如需登入帳號，請通過以下郵箱地址聯繫我們',
    six: '下載應用，如需登入帳號，請通過以下郵箱地址聯繫我們',
    seven: '登錄入口請點擊Log in，如需登錄帳號請聯繫我們'
  },
  title: {
    one: '基礎概念',
    two: '快訊',
    three: '觀點'
  },
  center: {
    title: '稀缺性',
    title_1: '專業性',
    title_2: '穩定性',
    title_3: '北美地區共同建設案例',
    title_4: '2024重點考察地區',
    title_5: '埃塞允許比特幣挖礦業務',
    title_6: '埃塞需要比特幣挖礦業務',
    content_5: '埃塞俄比亞政府積極擁抱新領域技術',
    content_6: '因龐氏騙局、黑市洗錢、非法交易導致央行限制加密貨幣業務',
    content_7: '政府因部分曠工的推動以及自身經濟發展的考慮允許比特幣挖礦業務開展，並通過會議討論',
    content_8: '目前從事加密貨幣業務的經營主體必須向INSA平台做申報並獲得審批',
    content_9: '挖礦強制要求支付美元電費，可以為埃塞政府創造大量的外匯現金；因與人提人陣的衝突美國取消了埃塞_非洲高增機會法案，導致埃塞直接損失一半以上的出口貿易規模，目前急切地需要美金外匯援助經濟建設；目前與國際貨幣基金組織、世界銀行談判援助性貸款陷入僵局，因官方匯率與黑市匯率差距太大',
    content_10: '埃塞俄比亞電力資源是位數不多的優質水電資源，但由於傳輸路線的嚴重損耗，電力出口到其他國家的成本較高；而本國目前工業水平較低，沒有大型耗能耗電的規模企業，過剩的電力無處消耗。',
    content_11: '俄羅斯不僅電力富餘，更重要的是電價低廉，1千瓦電力均價僅為4盧布，在歐洲居民用電鏈家排行榜上位列第二名。最後是天氣方面，俄羅斯氣候寒冷，部分地區甚至可以低至零下40度，可以為成千上萬的比特幣礦機提供良好的散熱條件。',
    content_12: '從世鏈礦業一路的發展歷程來看，深耕挖礦模式，開啟全產業鏈佈局可以說是兩大轉折點，佔據了很大分量。比如世鏈礦業在俄羅斯和美國都建有礦場，其中克拉斯諾亞爾斯克礦場的規模有3萬平方米，總功率容量也達到了10萬千瓦，裡面配備了專業的團隊駐場維修，在礦機安全性方面也可以得到保障。',
    content_13: '縱觀當前成功出海並存活下來的礦業，一般都有著清晰明確的全球化戰略發展方向，以及多年深耕區塊鏈行業的豐富經驗。正因如此，他們的行業地位會出現“出海”之後得到了進一步鞏固和提升。',
    one: '什麼是數據中心共建？',
    two: '數據中心共建是與有開發建設經驗、運營管理能力的合作方共同投資建設數據中心的業務。數據中心建成投產後由合作方運營管理，投資人按期享受分紅。',
    three: '為什麼選擇數據中心共建？',
    four: '稀缺性',
    five: '由於對加密貨幣發展的不同看法，各個國家對數字貨幣開採的政策也存在很大差異，合法數據中心未來是稀缺資源。',
    six: '專業性',
    seven: 'WKT有專業的數據中心運營團隊，可以為投資者挑選安全、穩定、電價低廉的數據中心，並提供專業的數據中心運維，使投資者可以更省心省力。',
    eight: '穩定性',
    nine: '客戶源穩定：以比特幣為例，比特幣價每上漲10%，可支撐新增20MW的比特幣數據中心40-70個。若考慮其他加密貨幣對數據中心的需求，這個數字還存有上浮空間（目前比特幣總市值接近加密貨幣總市值的50%）。',
    nine_1: '現金流穩定：一旦客戶選擇了數據中心，考慮到搬遷設備的時間、故障損耗、物流費用等因素，其更換數據中心的可能性極低。並且除非幣價低到一定程度導致挖礦收益低於電費成本，一般情況下客戶會一直開機挖礦。',
    nine_2: '收益率穩定：數據中心收益高低只與電價波動相關，而一般情況下電價較穩定。',
    nine_3: '業務現狀',
    district_ti: '地區',
    district: '北美',
    district_1: '俄羅斯',
    district_2: '埃塞俄比亞',
    district_3: '西亞',
    content: '在運行負荷超過60兆（MW），',
    content_1: '在建負荷約230兆瓦（MW）。',
    content_2: '計劃在2024年啟動約100-150MW的佈局。',
    content_3: '計劃在2024年啟動約80-120MW的佈局。',
    content_4: '在運行負荷約10MW，預計新增20-150MW。',
    img: '12',
  },
  table_ins: {
    title: '投顧業務方案-投顧業務要素表',
    title_1: '資金費率套利方案-資金費率套利要素表',
    label: "業務模式",
    label_1: '鎖定期',
    label_2: '收益分配',
    label_3: '止損條件',
    label_4: '風險',
    label_5: '備註',
    label_6: '風險和收益',
    label_7: '收益',
    content: "通過與客戶簽署投顧協議，代為操作客戶的虛擬貨幣交易賬戶現貨、合約交易，為客戶實現收益。",
    content_1: "1年",
    content_2: "賬戶初始淨值為1，根據賬戶淨值變化計算為客戶產生的收益，實行單客戶高水位法，每個自然月結束後根據實際為客戶產生的收益進行階梯式的分成。",
    content_3: "待补充",
    content_4: "賬戶初始淨值為1，根據賬戶淨值變化計算為客戶產生的收益，實行單客戶高水位法，每個自然月結束後根據實際為客戶產生的收益進行階梯式的分成。",
    content_5: "需要客戶提供自由的交易所賬戶交易權限，業務過程中不參與任何客戶的貨幣與法幣兌換，不進行虛擬貨幣資產的歸集操作。",
    content_6: "資金費率套利是一種 delta 中性策略，可讓交易者在現貨市場針對相同交易對持有與合約市場相反的倉位，進而達到對沖避險的效果。合約市場上由價格變動而導致的損失都可以有現貨市場的收益抵銷（反之亦然），讓交易者不需要平倉也能賺取資金費用。 ",
    content_7: "待定",
    content_8: "1.利率不穩定。超高額的資金費會促使人平倉，往往是曇花一現。",
    content_8: "2.爆倉風險。低額對冲保證金會增大風險，高額保證金會降低資金利用率從而降低收益率。",
    content_9: "回報率高。進幾個月，資金費都超過了年化50%，排行榜中少數非主流幣種達到數百甚至上千年化。",
  }
}
// eslint-disable-next-line no-unused-vars
const zh = {
  top: {
    one: '关于我们',
    two: '我们的优势',
    three: '业务介绍',
    four: '托管中心资源',
    six: '超算服务器',
    seven: '业务流程',
    eight: '核心团队',
    nine: '企业愿景',
    ten: '联系我们',
    twen: '首页',
    twe: '新闻动态',
    more: '更多',
    // 合作伙伴
    partner: '合作伙伴'
  },
  top_youshi: {
    five: '托管中心优势'
  },
  /* 业务介绍 */
  Bus_introduction: {
    one: '托管中心',
    two: '超算服务器',
    three: '算力收益播报',
    three_1: '数据中心共建',
    three_2: '区块链研究院',
    four: '算力播报',
    five: '理论收益计算器',
    six: '*温馨提示',
    seven: '收益计算器采用当前难度估算理论收益，可能与您的实际收益存在偏差，仅供参考。',
    eight: '有效算力',
    nine: '收益',
    ten: '币价',
    ele: '难度',
    elew: '费率',
    thirteen: 'BTC币价',
    fourteen: '难度',
    fifteen: '费率'
  },
  line: {
    one: '全网算力',
    two: '价格走势',
    three: '周',
    four: '月',
    five: '天'
  },
  /* 表格 */
  table_title: {
    one: '主流矿机',
    tow: '算力',
    three: '矿机功耗',
    four: '日产值',
    five: '日费电',
    six: '度',
    seven: '单台价格',
    eight: '回本天数'
  },
  /* 超算的产品的tab */
  tabs: {
    one: '产品一',
    two: '产品二',
    three: '产品三',
    four: '产品四'
  },
  /* 联系我们 */
  form: {
    one: '姓名',
    two: '公司名称',
    three: '邮箱',
    four: '联系电话',
    five: '客户留言',
    six: '请输入完整信息',
    seven: '添加成功'
  },
  /* 新闻 */
  news: {
    one: '公司新闻',
    two: '行业新闻',
    three: '科普知识',
    four: '新闻详情',
    five: '快讯',
    six: '来源: 央视新闻'
  },
  top_1: {
    one: '业务授权',
    two: '超算服务器托管中心规模与现状',
    three: '超算服务器托管中心展示'
  },
  lang: {
    one: '繁體中文',
    two: '简体中文',
    three: 'English',
    four: '日本語'

  },
  banner: {
    one: 'WKT',
    two: '悟空科技',
    three: 'Wukong Technology Limited'
  },
  section1: {
    one: '悟空科技（Wukong Technology Limited，简称：WKT）成立于2023年8月，位于中国香港。',
    two: 'WKT团队由一群金融、互联网、区块链行业从业十年以上的资深行业人士组成，大部分毕业于世界顶尖大学。团队曾参与管理的项目资产累计超过100亿美元。',
    three: 'WKT致力于服务全球市场，帮助全球的客户和投资人参与、享受全球的优质区块链专案和资源。'
  },
  section2: {
    one: '服务平台',
    two: 'WKT拥有多家美国知名超算服务器托管中心的全球业务授权',
    three: '资源丰富',
    four: '多家大型稳定合作商，提供专业服务',
    five: '贸易效率',
    six: '直接联系客户与合作商，实行高效贸易',
    seven: '实地考察',
    eight: '提供预约现场参观和超算服务器确认',
    nine: '现场运维',
    ten: '联合合作方提供保险保障、稳定电力、即时监控、专业运维团队等专业超算服务器托管中心条件'
  },
  section3: {
    one: 'BTC超算服务器托管中心共同建设',
    two: 'BTC超算服务器销售、回收和转售',
    three: 'BTC超算服务器托管业务',
    three1: '（北美地区）',
    four: 'BTC超算服务器托管业务',
    four1: '（安哥拉）',
    five: 'BTC超算服务器托管业务',
    five1: '（南非）',
    six: 'BTC超算服务器托管业务',
    six1: '（衣索比亚）'
  },
  section4_1: {
    one: 'WKT拥有多家美国知名超算服务器托管中心的全球业务授权',
    two: '俄克拉荷马州210MW',
    three: '印第安那州 41MW',
    four: '德州150MW',
    five: '*以上数据含计划扩建部分',
    six: '托管超算服务器数量超过一万五千台，销售超算服务器数量超过一万台。'
  },
  section4_2: {
    one: '数据中心规模',
    two: '印第安那州马里昂市BTC超算伺服器数据中心总规模为20MW, 现已通电。',
    three: '该超算伺服器数据中心，总共可容纳六千八百台超算伺服器同时运行。',
    four: '运营团队',
    five: '运营团队来自具有十年维护经验的业内顶级公司，由专业技术人员 24 小时进行算力监测，温度监测，超算服务器在线率高达98%。合作方比特部落拥有专业的超算服务器维修团队，和超算服务器备用部件，在超算服务器出现问题时快速维修重新上线。',
    // five1: '',
    six: '安保系统',
    seven: '超算服务器数据中心内有十六个4k摄像头，八个夜视摄像头，十二个红外线探测警报，直接连接警察局和消防局的安保系统。',
    eight: '供电技术',
    nine: '电力来源为不受限制的清洁能源，12.47kv三相y级对级德尔塔核电。清洁能源对BTC挖矿的优势非常明显，核电没有二氧化碳的污染，不受政府政策制约。',
    ten: '变电站的电线进入超算服务器数据中心，接入一进四出中压柜, 中压柜采用SEL751a系列断路保护装置。每个出线接一个变压器，变压器把12.47kv变到110kv。变压器出来接低压柜，低压柜搭载西门子LSIG四重保护断路保护器。最后连接每一个矿箱。所有的电缆从桥架通过，保障电路的稳定性和安全性。',
    eleven: 'BTC数据中心采用at&t光纤，spectrum光纤以及at&t无限路由器三重网络保障BTC数据中心网络稳定。'
  },
  section4_3_1: {
    title: '俄克拉荷马州专案',
    one: '俄克拉荷马州超算服务器托管中心',
    two: '210MW-2022年5月已通电',
    three: '位置：俄克拉荷马州',
    four: '负荷：210MW / 9,000个机位',
    five: '电力：国家电网',
    six: '资本支出：1,160万美元',
    seven: '电力协议：俄克拉荷马州电力公司，RTP定价，5年',
    eight: '土地：租赁'
  },
  section4_3_2: {
    title: '印第安纳-马里昂',
    one: '印第安纳超算服务器托管中心',
    two: '20MW-2022年6月已通电',
    three: '位置：印第安那州马里昂地区',
    four: '负荷：20MW',
    five: '电力：核电',
    seven: '电力协议：印第安那州密歇根电力公司，5年',
    eight: '土地：自有'
  },
  section4_3_3: {
    title: '德州专案',
    one: '德州超算服务器托管中心',
    two: '150MW-2022年3月已通电10MW',
    three: '位置：德州',
    four: '负荷：150MW',
    five: '电力：电网',
    six: '资本支出：255万美金',
    seven: '土地：自有'
  },
  section4_3_4: {
    title: '印第安那-曼西',
    one: '印第安那超算服务器托管中心',
    two: '11MW-2023年12月1日已通电',
    three: '位置：印第安那州曼西地区',
    four: '负荷：11MW / 3300个机位',
    five: '电力：核电',
    six: '电力协议：印第安那州密歇根电力公司，5年',
    seven: '土地：自有'
  },
  section5: {
    one: '电力及电价稳定：项目80%电力来自核能，20%电力为综合（瓦斯、火电），在通货膨胀和能源上涨的背景下，电力稳定不上涨。',
    two: '集群效应显著：当地政商关系稳健，已有稳定运营的20MW矿场就在同一地区，并有规划在建矿场三个，短时间内形成矿场集群效应。',
    three: '7X24实时管控：全场安防监控7x24小时，箱内监控7x24小时，现场有7x24小时的运维维护，算力达标率达到98%以上，可实时查看监控视频。',
    four: '当地环境安全先进：当地政策友好，资产得到保护，环境稳定、安全、高效，是高端最新机器落地托管的第一选择。'
  },
  section7: {
    one: '托管全流程安全透明，每一步都有清晰的记录和完善的法律档做保障，不参与客户分币流程，每一台超算服务器都在客户名下，客户的钱包直接绑定矿池分币。'
  },
  section8: {
    one: 'Vincent',
    two: '联合创始人&CEO',
    three: '拥有超过十年的金融领域项目投资经验，曾参与和管理的投融资项目规模超过20亿。',
    four: 'Kai Yat',
    five: '联合创始人&CTO',
    six: '超过10年的程式设计经验。自2018年起担任加密开发人员。 参与了分片区块链、加密钱包和多个DeFi项目的覈心开发。DeFi项目累计锁定总价值（TVL）超过1亿美元。',
    seven: 'Pom Jack',
    eight: '联合创始人&COO',
    nine: '2009年毕业于全球前100大学电脑专业，从事金融行业长达14年，曾管理的金融资产和投资项目累计超过50亿美金。'
  },
  section9: {
    one: 'WKT认为，未来随著区块链技术的发展，人们对加密货币的信息不断增强，BTC的全网共识和信仰将不可撼动。超算伺服器和超算伺服器数据中心作为BTC网路的必要组成部分，是当下不容错过的投资机遇。',
    two: 'WKT战略定位于建立区块链+超算服务器托管中心一站式科技平台，致力于打造区块链技术服务生态，深度布局超算服务器托管中心建设、超算服务器托管和数字货币投资三大板块，努力为客户和投资人做出贡献。'
  },
  section10: {
    one: '地址：香港尖沙咀海港城港威大厦5座33楼',
    two: '号码：852-51250672',
    three: '邮箱：webmaster@wukongbc.com'
  },
  goods: {
    one: '功能',
    two: '风冷矿机',
    three: '水冷矿机',
    four: '参数'
  },
  section11: {
    one: '印第安纳矿场'
  },
  other: {
    one: '托管中心视频',
    one1: '实时监控视频',
    two: '无人机视频1',
    three: '无人机视频2',
    four: '进场视频',
    five: '45天完工视频',
    six: '更多',
    seven: '更多新闻'
  },
  jiankong: {
    one: '托管中心外部监控样例',
    two: '托管中心内部监控样例',
    three: '外部即时监控请点击',
    four: '内部即时监控请点击',
    five: '如需登入帐号，请通过以下邮箱地址联系我们',
    six: '下载应用，如需登入帐号，请通过以下邮箱地址联系我们'
  },
  title: {
    one: '基础概念',
    two: '快讯',
    three: '观点'
  },
  news_detail: {
    one: '新闻详情',
    two: '来源：央视新闻',
    three: '发布时间：2023-08-08',
    four: '上一篇',
    five: '下一篇',
    six: '返回'
  },
  center: {
    title: '稀缺性',
    title_1: '专业性',
    title_2: '稳定性',
    title_3: '北美地区共同建设案例',
    title_4: '2024重点考察地区',
    title_5: '埃塞允许比特币挖矿业务',
    title_6: '埃塞需要比特币挖矿业务',
    content_5: '埃塞俄比亚政府积极拥抱新领域技术',
    content_6: '因庞氏骗局、黑市洗钱、非法交易导致央行限制加密货币业务',
    content_7: '政府因部分矿工的推动以及自身经济发展的考虑允许比特币挖矿业务开展，并通过议会讨论',
    content_8: '目前从事加密货币业务的经营主体须向INSA平台做申报并获得审批',
    content_9: '挖矿强制要求支付美元电费，可以为埃塞政府创造大量的外汇现金；因与提人阵的冲突美国取消了埃塞_非洲高增机会法案，导致埃塞直接损失一半以上的出口贸易规模，目前急切地需要美金外汇援助经济建设；目前与国际货币基金组织、世界银行谈判援助性贷款陷入僵局，因官方汇率与黑市汇率差距太大',
    content_10: '埃塞俄比亚电力资源是为数不多的优质水电资源，但由于传输线路的严重耗损，电力出口到其他国家成本较高；而本国目前工业水平较低，没有大型耗能耗电的规模企业，过剩的电力无处消耗。',
    content_11: '俄罗斯不仅电力富余，更重要的是电价低廉，1千瓦时电力均价仅为4卢布，在欧洲居民用电廉价排行榜上位列第二名。最后是天气方面，俄罗斯气候寒冷，部分地区甚至可以低至零下40度，可以为成千上万的比特币矿机提供良好的散热条件。',
    content_12: '从世链矿业一路的发展历程来看，深耕挖矿模式，开启全产业链布局可以说是两大转折点，占据了很大分量。比如世链矿业在俄罗斯和美国都建有矿场，其中克拉斯诺亚尔斯克矿场的规模就有3万平方米，总功率容量也达到了10万千瓦，里面配备了专业的团队驻场维修，在矿机安全性方面也可以得到保障。',
    content_13: '纵观当前成功出海并存活下来的矿业，一般都有着清晰明确的全球化战略发展方向，以及多年深耕区块链行业的丰富经验。正因如此，他们的行业地位会在“出海”之后得到了进一步巩固和提升。',
    one: '什么是数据中心共建？',
    two: '数据中心共建是与有开发建设经验、运营管理能力的合作方共同投资建设数据中心的业务。数据中心建成投产后由合作方运营管理，投资人按期享受分红。',
    three: '为什么选择数据中心共建？',
    four: '稀缺性',
    five: '由于对加密货币发展的不同看法，各个国家对数字货币开采的政策也存在很大差异，合法数据中心未来是稀缺资源。',
    six: '专业性',
    seven: 'WKT有专业的数据中心运营团队，可以为投资者挑选安全、稳定、电价低廉的数据中心，并提供专业的数据中心运维，使投资者可以更省心省力。',
    eight: '稳定性',
    nine: '客户源稳定：以比特币为例，比特币价每上涨10%，可支撑新增20MW的比特币数据中心40-70个。若考虑其他加密货币对数据中心的需求，这个数字还存有上浮空间（目前比特币总市值接近加密货币总市值的50%）。',
    nine_1: '现金流稳定：一旦客户选择了数据中心，考虑到搬迁设备的时间、故障损耗、物流费用等因素，其更换数据中心的可能性极低。并且除非币价低到一定程度导致挖矿收益低于电费成本，一般情况下客户会一直开机挖矿。',
    nine_2: '收益率稳定：数据中心收益高低只与电价波动相关，而一般情况下电价较稳定。',
    nine_3: '业务现状',
    district_ti: '地区',
    district: '北美',
    district_1: '俄罗斯',
    district_2: '埃塞俄比亚',
    district_3: '西亚',
    content: '在运行负荷超过60兆（MW），',
    content_1: '在建负荷约230兆瓦（MW）。',
    content_2: '计划在2024年启动约100-150MW的布局。',
    content_3: '计划在2024年启动约80-120MW的布局。',
    content_4: '在运行负荷约10MW，预计新增20-150MW。',
    img: '11',
  },
  table_ins: {
    title: '投顾业务方案-投顾业务要素表',
    title_1: '资金费率套利方案-资金费率套利要素表',
    label: "业务模式",
    label_1: '锁定期',
    label_2: '收益分配',
    label_3: '止损条件',
    label_4: '风险',
    label_5: '备注',
    label_6: '风险和收益',
    label_7: '收益',
    content: "通过与客户签署投顾协议，代为操作客户的虚拟货币交易所账户现货、合约交易，为客户实现收益。",
    content_1: "1年",
    content_2: "账户初始净值为1，根据账户净值变化计算为客户产生的收益，实行单客户高水位法，每个自然月结束后根据实际为客户产生的收益进行阶梯式的分成。",
    content_3: "待补充",
    content_4: "账户初始净值为1，根据账户净值变化计算为客户产生的收益，实行单客户高水位法，每个自然月结束后根据实际为客户产生的收益进行阶梯式的分成。",
    content_5: "需要客户提供自有的交易所账户交易权限，业务过程中不参与任何客户的货币与法币兑换，不进行虚拟货币资产的归集操作。",
    content_6: "资金费率套利是一种 delta 中性策略，可让交易者在现货市场针对相同交易对持有与合约市场相反的仓位，进而达到对冲避险的效果。合约市场上由于价格变动而导致的损失都可以由现货市场的收益抵销（反之亦然），让交易者不需平仓也能赚取资金费用。",
    content_7: "待定",
    content_8: "1.利率不稳定。超高额的资金费会促使人平仓，往往是昙花一现。",
    content_9: "2.爆仓风险。低额对冲保证金会增大风险，高额保证金会降低资金利用率从而降低收益率。",
    content_10: "回报率高。近几个月，资金费都超过了年化50% ，排行榜中少数非主流币种达到数百甚至上千年化。",
  }
}
// 日本语
const jp = {
  top: {
    one: '私たちについて',
    two: '私たちの利点',
    three: 'ビジネス紹介',
    four: '保管センターのリソース',
    six: 'スーパーコンピューターサーバー',
    seven: 'ビジネスプロセス',
    eight: 'コアチーム',
    nine: '企業ビジョン',
    ten: 'お問い合わせ',
    twen: 'ホーム',
    twe: 'ニュース',
    more: 'もっと',
    // 合作伙伴
    partner: 'パートナー'
  },
  top_youshi: {
    five: '保管センターの利点'
  },
  /* 业务介绍 */
  Bus_introduction: {
    one: '保管センター',
    two: 'スーパーコンピューターサーバー',
    three: 'ハッシュパワー収益放送',
    three_1: 'データセンター共同建設',
    three_2: 'ブロックチェーン研究所',
    four: 'ハッシュパワー放送',
    five: '理論収益計算機',
    six: '*注意',
    seven: '収益計算機は現在の難易度を推定して理論収益を計算し、実際の収益とは偏差がある可能性があります。参考までに。',
    eight: '有効なハッシュパワー',
    nine: '収益',
    ten: 'コイン価格',
    ele: '難易度',
    elew: '料率',
    thirteen: 'BTCコイン価格',
    fourteen: '難易度',
    fifteen: '料率'
  },
  line: {
    one: '全体のハッシュパワー',
    two: '価格の動向',
    three: '週',
    four: '月',
    five: '日'
  },
  /* 表格 */
  table_title: {
    one: '主流のマイニングマシン',
    tow: 'ハッシュパワー',
    three: 'マイニングマシンの消費電力',
    four: '日産量',
    five: '日電気料金',
    six: '度',
    seven: '単価',
    eight: '回収日数'
  },
  /* 超算の製品のtab */
  tabs: {
    one: '製品1',
    two: '製品2',
    three: '製品3',
    four: '製品4'
  },
  /* 联系我们 */
  form: {
    one: '名前',
    two: '会社名',
    three: 'メール',
    four: '電話番号',
    five: 'お客様のメッセージ',
    six: '完全な情報を入力してください',
    seven: '追加成功'
  },
  /* 新闻 */
  news: {
    one: '会社ニュース',
    two: '業界ニュース',
    three: '普及知識',
    four: 'ニュースの詳細',
    five: 'ニュース',
    six: '出典: CCTVニュース'
  },
  top_1: {
    one: 'ビジネス認可',
    two: 'スーパーコンピューターサーバー保管センターの規模と現状',
    three: 'スーパーコンピューターサーバー保管センターの展示'
  },
  // lang: {
  //   one: '繁体字',
  //   two: '簡体字',
  //   three: 'English',
  //   four: '日本語'
  // },
  // 重新翻译
  lang: {
    one: '繁体字',
    two: '简体中文',
    three: 'English',
    four: '日本語'
  },

  // banner: {
  //   one: 'WKT',
  //   two: '悟空科技',
  //   three: 'Wukong Technology Limited'
  // },
  // 重新翻译
  banner: {
    one: 'WKT',
    two: '悟空テクノロジーズ',
    three: 'Wukong Technology Limited'
  },

  section1: {
    one: 'Wukong Technology Limited（WKT）は2023年8月に設立され、香港に拠点を置いています。',
    two: 'WKTチームは、金融、インターネット、ブロックチェーン業界で10年以上の経験を持つシニア業界専門家からなり、ほとんどは世界トップクラスの大学を卒業しています。チームはこれまでに100億ドルを超えるプロジェクト資産を管理してきました。',
    three: 'WKTは、グローバル市場にサービスを提供し、グローバルのお客様と投資家がグローバルの優れたブロックチェーンプロジェクトやリソースを利用し、楽しむのを支援します。'
  },
  section2: {
    one: 'サービスプラットフォーム',
    two: 'WKTは、複数の米国の有名なスーパーコンピューターサーバー保管センターのグローバルビジネスライセンスを保有しています。',
    three: 'リソース豊富',
    four: '多くの大規模で安定したパートナーが',
    five: '専門サービスを提供',
    six: '直接的に顧客とパートナーと連絡を取り、効率的な貿易を実施',
    seven: '現地調査',
    eight: '予約制の現地見学とスーパーコンピューターサーバーの確認を提供',
    nine: '現地運用',
    ten: '共同パートナーが提供する保険、安定した電力、リアルタイムモニタリング、専門運用チームなどの専門スーパーコンピューターサーバー保管センター条件'
  },
  section3: {
    one: 'BTCスーパーコンピューターサーバー共同建設',
    two: 'BTCスーパーコンピューターサーバー販売、回収、転売',
    three: 'BTCスーパーコンピューターサーバー保管ビジネス',
    three1: '（北米地域）',
    four: 'BTCスーパーコンピューターサーバー保管ビジネス',
    four1: '（アンゴラ）',
    five: 'BTCスーパーコンピューターサーバー保管ビジネス',
    five1: '（南アフリカ）',
    six: 'BTCスーパーコンピューターサーバー保管ビジネス',
    six1: '（エチオピア）'
  },
  section4_1: {
    one: 'WKTは、複数の米国の有名なスーパーコンピューターサーバー保管センターのグローバルビジネスライセンスを保有しています。',
    two: 'オクラホマ州210MW',
    three: 'インディアナ州41MW',
    four: 'テキサス150MW',
    five: '*上記のデータには計画された拡張部分が含まれています',
    six: '保管されているスーパーコンピューターサーバーの台数は1万5,000台を超え、販売されたスーパーコンピューターサーバーの台数は1万台を超えています。'
  },
  section4_2: {
    one: 'データセンター規模',
    two: 'インディアナ州マリオン市のBTCスーパーコンピューターサーバーデータセンターの総規模は20MWで、すでに通電しています。',
    three: 'このスーパーコンピューターサーバーデータセンターは、合計6,800台のスーパーコンピューターサーバーを同時に稼働させることができます。',
    four: '運営チーム',
    five: '運営チームは、十年のメンテナンス経験を持つ業界トップクラスの企業からのプロフェッショナル技術者で構成されており、専門技術者による24時間のハッシュパワーモニタリング、温度モニタリング、スーパーコンピューターサーバーのオンライン率は98%に達しています。パートナーのビットトライブは、専門のスーパーコンピューターサーバー修理チームとスーパーコンピューターサーバー予備部品を所有し、スーパーコンピューターサーバーに問題が発生した場合、迅速に修理して再びオンラインにします。',
    // five1: '',
    six: 'セキュリティシステム',
    seven: 'スーパーコンピューターサーバーデータセンター内には、16台の4kカメラ、8台のナイトビジョンカメラ、12台の赤外線探知警報、警察署および消防署に直接接続されたセキュリティシステムがあります。',
    eight: '電力技術',
    nine: '電力は制限なしのクリーンエネルギー、12.47kv三相yレベルデルタ原子力から供給されます。クリーンエネルギーはBTCマイニングに非常に有利であり、原子力は二酸化炭素の汚染がなく、政府の政策に制約されません。',
    ten: '変電所の電線がスーパーコンピューターサーバーデータセンターに入り、1対4の中圧キャビネットに接続され、中圧キャビネットにはSEL751aシリーズの遮断保護装置が搭載されています。各出力線には変圧器が接続され、変圧器は12.47kvを110kvに変換します。変圧器の出力は低圧キャビネットに接続され、低圧キャビネットには西門子LSIG四重保護遮断器が搭載されています。最後に、各マイニングボックスに接続されます。すべてのケーブルはブリッジを通過し、回路の安定性と安全性を確保します。',
    eleven: 'BTCデータセンターは、at&tファイバー、スペクトルファイバー、at&t無線ルーターのトリプルネットワークでBTCデータセンターのネットワークを安定させます。'
  },
  section4_3_1: {
    title: 'インディアナ州マリオン',
    one: 'インディアナ州スーパーコンピューターサーバー保管センター',
    two: '20MW-2022年6月に通電',
    three: '位置：インディアナ州マリオン地区',
    four: '負荷：20MW',
    five: '電力：核電力',
    six: '資本支出：1,160万米ドル',
    seven: '電力契約：インディアナ州ミシガン電力会社、5年',
    eight: '土地：自有'
  },
  section4_3_2: {
    title: 'インディアナ州マリオン',
    one: 'インディアナ州スーパーコンピューターサーバー保管センター',
    two: '20MW-2022年6月に通電',
    three: '位置：インディアナ州マリオン地区',
    four: '負荷：20MW',
    five: '電力：核電力',
    seven: '電力契約：インディアナ州ミシガン電力会社、5年',
    eight: '土地：自有'
  },

  section4_3_3: {
    title: 'テキサス',
    one: 'テキサススーパーコンピューターサーバー保管センター',
    two: '150MW-2022年3月に通電10MW',
    three: '位置：テキサス',
    four: '負荷：150MW',
    five: '電力：電網',
    six: '資本支出：255万米ドル',
    seven: '土地：自有'
  },
  section4_3_4: {
    title: 'インディアナ-マンシー',
    one: 'インディアナスーパーコンピューターサーバー保管センター',
    two: '11MW-2023年12月1日に通電',
    three: '位置：インディアナ州マンシー地区',
    four: '負荷：11MW / 3300台',
    five: '電力：原子力',
    six: '電力契約：インディアナ州ミシガン電力会社、5年',
    seven: '土地：自有'
  },
  section5: {
    one: '電力と電価の安定性：プロジェクトの80%の電力は核エネルギーから供給され、20%は総合（ガス、火力）から供給されます。インフレーションとエネルギーの上昇の背景で、電力の安定性は上昇しません。',
    two: 'クラスター効果が顕著：地元の政商関係が安定しており、すでに安定して稼働している20MWのマイニング施設が同じ地域にあり、建設中の施設が3つあり、短期間でマイニング施設クラスター効果を形成します。',
    three: '7X24リアルタイム制御：全体のセキュリティ監視は7x24時間、ボックス内の監視は7x24時間、現場には7x24時間の運用保守があり、ハッシュパワー達成率は98%以上に達し、リアルタイムで監視ビデオを表示できます。',
    four: '地元の環境安全が先進的：地元の政策は友好的で、資産が保護され、環境が安定しており、安全で効率的であり、最新の機器が設置された高度なセキュリティシステムがあります。'
  },
  section7: {
    one: '共同保管センターの全プロセスは安全で透明であり、すべてのステップには明確な記録と完全な法的文書があり、顧客のコイン分割プロセスには参加せず、すべてのスーパーコンピューターサーバーは顧客名義であり、顧客のウォレットはプールコインに直接バインドされます。'
  },
  section8: {
    one: 'Vincent',
    two: '共同創設者＆CEO',
    three: '10年以上の金融分野のプロジェクト投資経験を持ち、管理した投資プロジェクトの規模は200億ドルを超えています。',
    four: 'Kai Yat',
    five: '共同創設者＆CTO',
    six: '10年以上のプログラミング経験。2018年から暗号開発者として活動。分散型台帳技術、暗号財布、および複数のDeFiプロジェクトの開発に参加。DeFiプロジェクトの累計ロック総額（TVL）は1億ドルを超えています。',
    seven: 'Pom Jack',
    eight: '共同創設者＆COO',
    nine: '2009年に世界トップ100の大学でコンピュータ専攻を卒業し、金融業界で14年間活動し、管理した金融資産と投資プロジェクトは累計50億ドルを超えています。'
  },
  section9: {
    one: 'WKTは、ブロックチェーン技術の発展に伴い、暗号通貨に対する人々の情報が増加し、BTCの全体的な合意と信頼は揺るぎません。スーパーコンピューターサーバーとスーパーコンピューターサーバーデータセンターはBTCネットワークの必須構成要素であり、今後の投資機会を逃すべきではありません。',
    two: 'WKTの戦略は、ブロックチェーン+スーパーコンピューターサーバー共同保管センターの一貫した科学技術プラットフォームを構築し、ブロックチェーン技術サービスエコシステムを構築し、スーパーコンピューターサーバー共同保管センターの建設、スーパーコンピューターサーバー共同保管、デジタル通貨投資の3つの分野に深く関与し、顧客と投資家に貢献します。'
  },
  section10: {
    one: '住所：香港尖沙咀海港城港威大厦5座33階',
    two: '電話：852-51250672',
    three: 'メール：webmaster@wukongbc.com'
  },
  goods: {
    one: '機能',
    two: '風冷マイニングマシン',
    three: '水冷マイニングマシン',
    four: 'パラメータ'
  },
  section11: {
    one: 'インディアナ鉱山'
  },
  other: {
    one: '保管センターのビデオ',
    one1: 'リアルタイム監視ビデオ',
    two: 'ドローンビデオ1',
    three: 'ドローンビデオ2',
    four: '入場ビデオ',
    five: '45日完成ビデオ',
    six: 'もっと',
    seven: 'もっとニュース'
  },
  jiankong: {
    one: '保管センターの外部監視例',
    two: '保管センターの内部監視例',
    three: '外部リアルタイム監視はこちらをクリック',
    four: '内部リアルタイム監視はこちらをクリック',
    five: 'アカウントにログインする場合は、以下のメールアドレスにお問い合わせください',
    six: 'アプリをダウンロードし、アカウントにログインする場合は、以下のメールアドレスにお問い合わせください'
  },
  title: {
    one: '基本概念',
    two: '速報',
    three: '視点'
  },
  news_detail: {
    one: 'ニュース詳細',
    two: '出典：CCTVニュース',
    three: '公開日：2023-08-08',
    four: '前の記事',
    five: '次の記事',
    six: '戻る'
  },
  center: {
    title: '希少性',
    title_1: '専門性',
    title_2: '安定性',
    title_3: '北米地域共同建設事例',
    title_4: '2024年重点視察地域',
    title_5: 'エチオピアがビットコインマイニングビジネスを許可',
    title_6: 'エチオピアがビットコインマイニングビジネスを必要としています',
    content_5: 'エチオピア政府は新技術を積極的に受け入れています',
    content_6: 'ペニーピンチ、ブラックマーケットの洗浄、不法取引により、中央銀行は暗号通貨ビジネスを制限しています',
    content_7: '政府は一部の鉱夫の推進と自国の経済発展を考慮して、ビットコインマイニングビジネスを展開し、議会で議論しています',
    content_8: '現在、暗号通貨ビジネスを行っている事業主体はINSAプラットフォームに申告し、承認を得る必要があります',
    content_9: 'マイニングは強制的に米ドルで電気料金を支払う必要があり、エチオピア政府に多額の外貨現金を提供することができます。ペニーピンチ、ブラックマーケットの洗浄、不法取引により、中央銀行は暗号通貨ビジネスを制限しています',
    content_10: 'エチオピアの電力資源は数少ない優れた水力資源の一つですが、送電線路の深刻な損耗のため、電力の輸出コストが高くなっています。また、国内の工業レベルが低く、大規模なエネルギー消費企業がないため、余剰電力は消費されていません。',
    content_11: 'ロシアは電力が豊富であるだけでなく、電力価格が安いことが重要です。1キロワット時の電力価格は平均4ルーブルで、ヨーロッパの住民用電力価格ランキングで2位です。最後に、天候の面では、ロシアの気候は寒冷であり、一部の地域ではマイナス40度まで低下することができ、数千台のビットコインマイニングマシンに良好な冷却条件を提供できます。',
    content_12: '世鎖鉱業の発展過程を見ると、マイニングモードを深耕し、全産業鎖の配置を開始することは、大きな分量を占めています。世鎖鉱業はロシアとアメリカに鉱山を建設しており、そのうちクラスノヤルスク鉱山の規模は3万平方メートルで、総電力容量は10万キロワットに達し、専門チームが駐在して修理を行っており、鉱山の安全性にも保証があります。',
    content_13: '現在の成功した海外進出と生存している鉱業を見ると、明確なグローバル戦略的発展方向と、数年間のブロックチェーン業界での豊富な経験を持っていることが一般的です。そのため、彼らの業界地位は「海外」の後もさらに強化され、向上します。',
    one: 'データセンター共同建設とは何ですか？',
    two: 'データセンター共同建設とは、開発・建設経験と運営・管理能力を持つ協力者と共同でデータセンターを投資・建設するビジネスです。データセンターが完成し、運用開始後、投資家は定期的に配当を受け取ります。',
    three: 'なぜデータセンター共同建設を選ぶのですか？',
    four: '希少性',
    five: '暗号通貨の発展に対する異なる見解により、各国のデジタル通貨採掘に対する政策には大きな違いがあり、合法的なデータセンターは将来的には希少な資源となります。',
    six: '専門性',
    seven: 'WKTには専門のデータセンター運用チームがおり、安全で安定した、電力料金が安いデータセンターを選択し、専門のデータセンター運用を提供することで、投資家がより安心して投資できるようにします。',
    eight: '安定性',
    nine: '顧客源が安定しています：ビットコインを例に挙げると、ビットコイン価格が10%上昇すると、新しい20MWのビットコインデータセンター40-70台を追加できます。他の暗号通貨がデータセンターに必要とすることを考慮すると、この数字には上昇の余地があります（現在のビットコインの総市場価値は暗号通貨の総市場価値の50%に近い）。',
    nine_1: 'キャッシュフローが安定しています：顧客がデータセンターを選択すると、機器の移動時間、故障損耗、物流費用などを考慮すると、データセンターを変更する可能性は非常に低いです。また、通常、顧客はマイニングを続けるために常にオンにしています。',
    nine_2: '収益率が安定しています：データセンターの収益は電力価格の変動にのみ関連しており、通常、電力価格は比較的安定しています。',
    nine_3: 'ビジネスの現状',
    district_ti: '地域',
    district: '北米',
    district_1: 'ロシア',
    district_2: 'エチオピア',
    district_3: '西アジア',
    content: '運用負荷が60MWを超えると、',
    content_1: '建設中の負荷は約230MWです。',
    content_2: '2024年に約100-150MWの展開を開始する予定です。',
    content_3: '2024年に約80-120MWの展開を開始する予定です。',
    content_4: '運用負荷は約10MWで、追加20-150MWを予定しています。',
    img: '11',
  },
  table_ins: {
    title: 'アドバイザリーサービス-アドバイザリーサービス要素表',
    title_1: '資金金利アービトラージプラン-資金金利アービトラージ要素表',
    label: "ビジネスモデル",
    label_1: 'ロック期間',
    label_2: '利益分配',
    label_3: 'ストップロス条件',
    label_4: 'リスク',
    label_5: '備考',
    label_6: 'リスクとリターン',
    label_7: 'リターン',
    content: "顧客とアドバイザリー契約を締結し、顧客の仮想通貨取引所口座の現物、先物取引を代行し、顧客に利益をもたらします。",
    content_1: "1年",
    content_2: "アカウントの初期純資産が1である場合、アカウントの純資産の変化に基づいて顧客に利益をもたらし、単一の顧客の高水準法を実施し、各自然月の終了後、顧客に実際にもたらされた利益に応じて段階的に分配します。",
    content_3: "補充待ち",
    content_4: "アカウントの初期純資産が1である場合、アカウントの純資産の変化に基づいて顧客に利益をもたらし、単一の顧客の高水準法を実施し、各自然月の終了後、顧客に実際にもたらされた利益に応じて段階的に分配します。",
    content_5: "顧客は自分の取引所アカウントの取引権限を提供する必要があり、ビジネスプロセス中には顧客の通貨と法定通貨の交換に関与せず、仮想通貨資産の集約操作は行いません。",
    content_6: "資金金利アービトラージは、デルタ中立戦略であり、トレーダーが同じ取引ペアに対して現物市場で持ちポジションと契約市場で逆ポジションを持つことで、ヘッジ効果を達成することができます。契約市場での価格変動による損失は、現物市場の収益で相殺されます（逆も然り）、トレーダーは清算せずに資金料を獲得できます。",
    content_7: "未定",
    content_8: "1. 利率は不安定です。非常に高い資金料は人々を清算に追い込み、一過性のものです。",
    content_9: "2. ロスカットリスク。低いヘッジ証拠金はリスクを増大させ、高い証拠金は資金利用率を下げ、収益率を下げます。",
    content_10: "リターンが高い。過去数ヶ月、資金料は年率50%を超え、ランキングでは、一部の非主流通貨が数百、数千年率に達しています。",
  }
}



export function returnLanguage(type) {
  // 换一种写法
  switch (type) {
    case '简体中文':
      return zh
    case '繁体中文':
      return tw
    case 'English':
      return en
    case '日本語':
      return jp
    default:
      return en
  }
}

export default {
  returnLanguage
}
